

// Css
import React from "react";
import App from "./App";
import 'bootstrap/dist/css/bootstrap.min.css'; 
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from "react-dom/client";
import ScrollToTop from './helper/ScrollToTop';
import { GlobalStyle } from "./components/common/styles/global.js";


const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

serviceWorker.unregister();


root.render(
  <BrowserRouter>

    <GlobalStyle />
    <ScrollToTop />
    
    <App />
  </BrowserRouter>
);