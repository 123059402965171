import React from 'react';

export default function Appointment(model) {
  const { appointmentData } = model.data;
 


  return (
    <div className="showtime-preview">
      <div> <a style = {{color:'white', fontSize:14,  fontFamily: 'Arial'}}>Öğrenci Adı: {appointmentData.text}</a></div>
      <div> <a style = {{textAlign:'center',color:'white', fontSize:13,  fontFamily: 'Arial'}}>Neden: {appointmentData.Reservation_Reason}</a></div>


      <div>
      
      </div>
    </div>
  );
}
