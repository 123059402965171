import React, { Component } from 'react';
import { Styles } from '../styles/courseSearch.js';

class CourseSearch extends Component {



     onFormSubmit = async(e) => {
   
   const searchName = document.getElementById("searchName");


    
            e.preventDefault();

            const nameValue = searchName.value.trim();
          this.props._getEventSearch(nameValue);


        }



    render() {
        return (
            <Styles>
                {/* Course Search */}
                <div className="course-search">
                    <h5>Etkinlik Ara</h5>
                    <form>
                        <input type="text"  id="searchName" name="search" placeholder="Etkinlik Bilgileri Giriniz" />
                        <button onClick={this.onFormSubmit} type="submit"><i className="las la-search"></i></button>
                    </form>
                </div>
            </Styles>
        )
    }
}

export default CourseSearch
