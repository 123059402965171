import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';

import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/event.js';
import axios from 'axios';


const urlLink = 'https://kto.stechomeyazilim.info';



class Project extends Component {
    constructor() {
        super();
        this.state = {
            ArraySSS: [],
          docs:[],

        };
    }
    
    async componentDidMount() {


await this._starting('bos');
}


_searchProject= async (value,value1) => {
       this.setState({ ArraySSS: [],
       docs : [] })

     try {      

await axios.get(`https://kto.stechomeyazilim.info:5000/project/search/${value}/${value1}`)
      .then(res => {

  this.setState({
                ArraySSS : res.data
          }, () => {



          })

      })
  
              

        } catch (error) {
          
          console.log("error" + error)
        }

}


_starting = async (value) => {
       this.setState({ ArraySSS: [],
       docs : [] })

     try {      
let value1 = null

if(this.props.location.state ==null)
{ 
    await axios.get(`https://kto.stechomeyazilim.info:5000/student/select/1/${value}`)
      .then(res => {

  this.setState({
                ArraySSS : res.data
          }, () => {

                 
     res.data.map((eventData, i) => (
       this.setState({ docs: [...this.state.docs,{ uri : urlLink + "/" + eventData.Study_Pdf_Link}] })
                                            ))     

          })

      })
  }
  if(this.props.location.state.ID !=null){
 await axios.get(`https://kto.stechomeyazilim.info:5000/student/select/${this.props.location.state.ID}/${value}`)
      .then(res => {

  this.setState({
                ArraySSS : res.data
          }, () => {

                 
     res.data.map((eventData, i) => (
       this.setState({ docs: [...this.state.docs,{ uri : urlLink + "/" + eventData.Study_Pdf_Link}] })
                                            ))     

          })

      })
  }

              

        } catch (error) {
          
          console.log("error" + error)
        }

}


    render() {
        return (
           <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper event-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Proje Örnekleri" />

                    {/* Events Area */}
                    <section className="event-page-area">

                    <Container>
                            <Row>
                                <Col lg="9" md="12">

<div class="">
<div class="">
<h3 class="panel-page-title">Proje &Ouml;rnekleri</h3>
</div>
<div class="main-content ">
<div class="page-inner">
<p>Genel olarak hazırlanan projeler&nbsp;<em><strong>&Ouml;zet/Abstract, Ama&ccedil; ve Hedefler, Konu, Kapsam ve Literat&uuml;r &Ouml;zeti, &Ouml;zg&uuml;n Değer, Y&ouml;ntem, Proje Y&ouml;netimi, Ekip ve Araştırma Olanakları ile Yaygın Etki&nbsp;</strong></em>b&ouml;l&uuml;mlerinden oluşmalıdır.</p>
<p><strong>Proje &Ouml;nerisi &Ouml;rnekleri (T&Uuml;BİTAK):</strong></p>
<p><a href="http://www.tubitak.gov.tr/sites/default/files/kbag_1001_proje_ornegi.pdf" target="_blank" rel="noopener">Kimya ve Biyoloji Araştırma Destek Grubu (KBAG)</a><br /><a href="http://www.tubitak.gov.tr/sites/default/files/mfag_1001_proje_ornegi.pdf" target="_blank" rel="noopener">Matematik ve Fizik Araştırma Destek Grubu (MFAG)</a><br /><a href="http://www.tubitak.gov.tr/sites/default/files/sbag_1001_proje_ornegi.pdf" target="_blank" rel="noopener">Sağlık Bilimleri Araştırma Destek Grubu (SBAG)</a><br /><a href="http://www.tubitak.gov.tr/sites/default/files/eeeag_1001_proje_ornegi.pdf" target="_blank" rel="noopener">Elektrik, Elektronik ve Enformatik Araştırma Destek Grubu (EEEAG)</a><br /><a href="http://www.tubitak.gov.tr/sites/default/files/mag_1001_proje_ornegi.pdf" target="_blank" rel="noopener">M&uuml;hendislik Araştırma Destek Grubu (MAG)</a><br /><a href="http://www.tubitak.gov.tr/sites/default/files/1001_proje_ornegi_caydag.pdf" target="_blank" rel="noopener">&Ccedil;evre, Atmosfer, Yer ve Deniz Bilimleri Araştırma Destek Grubu (&Ccedil;AYDAG)</a><br /><a href="http://www.tubitak.gov.tr/sites/default/files/tovag_1001_proje_ornegi.pdf" target="_blank" rel="noopener">Tarım, Ormancılık ve Veterinerlik Araştırma Destek Grubu (TOVAG)</a><br /><a href="http://www.tubitak.gov.tr/sites/default/files/sobag_1001_proje_ornegi.pdf" target="_blank" rel="noopener">Sosyal ve Beşeri Bilimler Araştırma Destek Grubu (SOBAG)</a></p>
<p><strong>Proje &Ouml;nerisinin Ekleri i&ccedil;in &Ouml;rnekler:</strong></p>
<p><a href="http://www.tubitak.gov.tr/sites/default/files/1001_ek1_kaynaklar_ornek.pdf" target="_blank" rel="noopener">EK1 - Kaynaklar</a><br /><a href="http://www.tubitak.gov.tr/sites/default/files/1001_ek2_butce_ve_gerekcesi_ornek.pdf" target="_blank" rel="noopener">EK2 -&nbsp;B&uuml;t&ccedil;e ve Gerek&ccedil;esi</a></p>
</div>
</div>
</div>
</Col>

</Row>
</Container>
</section>
                    {/* Footer 2 */}
                    <FooterTwo />

                </div>
            </Styles>


           
        );
    }
}

export default Project;