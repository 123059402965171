  import React, { Component } from 'react';
import { Styles } from '../styles/courseCategory.js';
import { Link } from 'react-router-dom';


class CourseCategory extends Component {
     constructor() {

            super();
        this.state = {
        arrayLesson:[],
        };
    }
    


         async componentDidMount() {


}

    render() {
        return (
            <Styles>
                {/* Course Tag */}
                <div className="course-category">
                    <h5>KTO TTO</h5>
                    <ul className="category-item list-unstyled">

                    
                                                <li className="check-btn">
                           <Link className="nav-link" to={process.env.PUBLIC_URL + "/ekibimiz"}>Ekibimiz</Link>

                        </li>
                                         <li className="check-btn">
                                                                    <Link className="nav-link" to={process.env.PUBLIC_URL + "/tanitimmateryal"}>Tanıtım Materyalleri</Link>
                        </li>
                                                  
                                                             <li className="check-btn">
                                               <Link className="nav-link" to={process.env.PUBLIC_URL + "/tanitimvideo"}>Tanıtım Videoları</Link>


                        </li>

                                                                 <li className="check-btn">
                                          <Link className="nav-link" to={process.env.PUBLIC_URL + "/mevzuat"}>Mevzuat</Link>

                        </li>
          

                       
                    
                    </ul>
                </div>
            </Styles>
        )
    }
}

export default CourseCategory
