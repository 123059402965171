import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { Styles } from './styles/contact.js';
import { colors } from "../../components/common/element/elements.js";

const AnyReactComponent = ({ text }) => <div>{text} <i style = {{ color: colors.green2}} class="fas fa-map-pin fa-5x"></i></div>;

class GoogleMap extends Component {

    static defaultProps = {
        center: {
            lat: 37.8657337,
            lng: 32.5348285
        },
        zoom: 11
    };


    render() {
        return (
            <Styles>
                {/* Google Map */}
                <div className="google-map-area">
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: "AIzaSyBocJDGN0UtiQXa-R4Aa7salQeABS9XdBA" }}
                        defaultCenter={this.props.center}
                        defaultZoom={this.props.zoom}
                    >
                        <AnyReactComponent lat={37.8657337} lng={32.5348285} text="KTO Karatay Üniversitesi" />
                    </GoogleMapReact>
                </div>
            </Styles>
        )
    }
}

export default GoogleMap