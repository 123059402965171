import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/stickyMenu.js";
import axios from 'axios';


class StickyMenu extends Component {
            constructor() {
        super();
        this.state = {
            ArrayProjectCategory: [],
          ArrayStudentCategory:[],

        };
    }
    
    async componentDidMount() {

   window.addEventListener("scroll", () => {
            const stickyMenu = document.querySelector(".sticky-menu");

            if (window.scrollY > 160) {
                stickyMenu.classList.add("sticky");
            } else {
                stickyMenu.classList.remove("sticky");
            }
        });

        await this._starting();
}

_starting = async () => {

    await this._getProjectsCategory();
    await this._getStudentCategory();
    
}




_getStudentCategory= async () => {
        try {      
    

await axios.get('https://kto.stechomeyazilim.info:5000/studentCategory/select')
      .then(res => {

  this.setState({
                ArrayStudentCategory : res.data

          }, () => {

          })

      })
  
              

        } catch (error) {
          
          console.log("error" + error)
        }
      }

_getProjectsCategory= async () => {
        try {      
    

await axios.get('https://kto.stechomeyazilim.info:5000/projectsCategory/select/')
      .then(res => {

  this.setState({
                ArrayProjectCategory : res.data

          }, () => {

          })

      })
  
              

        } catch (error) {
          
          console.log("error" + error)
        }
      }

    render() {
    return (
        <Styles>
            {/* Sticky Menu */}
            <section className="sticky-menu">
                <Container>
                    <Row>
                       <Col md="3">
                            <div className="logo">
                            </div>
                        </Col>
                        <Col md="9">

                            <div className="menu-box d-flex justify-content-end">
                                   <ul className="nav menu-nav">
                                        <li className="nav-item dropdown active">
                                            <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/"} data-toggle="dropdown">Anasayfa</Link>
                                          
                                        </li>
                                        <li className="nav-item dropdown">
                                            <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/"} data-toggle="dropdown">Hakkımızda <i className="las la-angle-down"></i></Link>
                                            <ul className="dropdown list-unstyled">
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/ekibimiz"}>Ekibimiz</Link></li>
                                              
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/tanitimvideo"}>Proje Görselleri</Link></li>
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/mevzuat"}>Yönergeler</Link></li>
                                                



                                           
                                            </ul>
                                        </li>
                                                                                <li className="nav-item dropdown">
                                            <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/"} data-toggle="dropdown">Projeler <i className="las la-angle-down"></i></Link>
                                           <ul className="dropdown list-unstyled">
                                             {
                                                        this.state.ArrayProjectCategory.map((data,i ) => ( 
                                                <li  key = {i} className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/" +(data.Projects_Category_Name).replace(/\s+/g, '-').toLowerCase()}>{data.Projects_Category_Name}</Link></li>
                         ))
                                                    }
                                                                                            <li className="nav-item dropdown">

                                                     <ul id='menu'>
    <li className="nav-item"><a href="/#" title='Menu'>Öğrenci Projeleri</a>
    
     <ul className='menus'>
    

  {
                                                        this.state.ArrayStudentCategory.map((data,i ) => ( 
                                                       <li  key = {i} className='has-submenu'><Link to={{pathname: `/ogrenci-projeleri/${(data.Project_Category_Name).replace(/\s+/g, '-').toLowerCase()}`, state: { ID: data.ID }}}>{data.Project_Category_Name}</Link></li>

                         ))
                                                    }

                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/proje-yukle"}>Proje Yükleme</Link></li>

       
       </ul>
    </li>
  </ul>
    </li>                 

     <li className="nav-item dropdown">

                                                     <ul id='menu'>
    <li className="nav-item"><a href="/#"  title='Menu'>Proje Çağrıları</a>
    
     <ul className='menus'>
    
                                                       <li  className='has-submenu'><a target="_blank" rel="noopener noreferrer" href='https://www.tubitak.gov.tr/tr/destekler/akademik/ulusal-destek-programlari'>TÜBİTAK Çağrıları</a></li>

                   
                                                     <li><a target="_blank" rel="noopener noreferrer" href='https://h2020.org.tr/tr' title='Dropdown 3'>Ufuk 2020 AB Proje Destekleri</a></li>
     
                                                          <li><a target="_blank" rel="noopener noreferrer" href='https://www.kosgeb.gov.tr/site/tr/genel/destekler/3/destekler' title='Dropdown 3'>KOSGEB</a></li>
  
                                                              <li><a target="_blank" rel="noopener noreferrer" href='http://acikerisim.karatay.edu.tr' title='Dropdown 3'>DSPACE</a></li>

                                                            <li><a target="_blank" rel="noopener noreferrer" href='https://ttopanel.karatay.edu.tr' title='Dropdown 3'>TTO Erişim</a></li>
       </ul>
    </li>
  </ul>
    </li>     

    <li className="nav-item dropdown">

<ul id='menu'>
<li className="nav-item"> <Link to={process.env.PUBLIC_URL + "/proje-ornekleri"} title='Menu'>Proje Örnekleri</Link>

</li>
</ul>
</li>                     

                     </ul>
                                        </li>
                                      
                                         <li className="nav-item dropdown">
                                            <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/"} data-toggle="dropdown">Yayınlar <i className="las la-angle-down"></i></Link>
                                            <ul className="dropdown list-unstyled">
                                                <li className="nav-item"><a className="nav-link" target="_blank" rel="noopener noreferrer" href='http://acikerisim.karatay.edu.tr' title='Dropdown 3'>DSPACE</a></li>
                                                <li className="nav-item"><a className="nav-link" target="_blank" rel="noopener noreferrer" href='http://ttopanel.karatay.edu.tr' title='Dropdown 3'>Teşvik Başvurusu</a></li>
                                           
                                            </ul>
                                        </li>
                                      
                                         <li className="nav-item dropdown">
                                            <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/iletisim"} data-toggle="dropdown">İletisim</Link>
                                        </li>

                                             

                                    </ul>
                                <div className="apply-btn">
                                    <Link to={process.env.PUBLIC_URL + "/etkinlikler"}><i className="las la-clipboard-list"></i>Etkinlikler</Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Styles>
    )
}
}
export default StickyMenu