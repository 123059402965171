import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import GoogleMap from './GoogleMap';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/contact.js';
import axios from 'axios';

class Contact extends Component {
  constructor() {
        super();
        this.state = { 
               Adress_Text: "",
               Email_Adress:"",
               Phone_Numer:"",
        };
                this.onFormSubmit = this.onFormSubmit.bind(this);

    }
    
  async componentDidMount() {

        await this._starting();

}

_starting = async () => {

     try{
     
     await axios.get('https://kto.stechomeyazilim.info:5000/getContact/select/')
      .then(res => {
        
         res.data.map((data, i) => ( 

     this.setState({
Adress_Text: data.Adress_Text,
Email_Adress : data.Email_Adress,
Phone_Numer : data.Phone_Numer

                  }, () => {
                       
                       console.log("Adress_Text", res.data.cikti)
                  })

                         ))

      })

    }
    catch (error) {
      
      console.log("error" + error)
    }


}


      


       onFormSubmit = async(e) => {

        const name = document.getElementById("contact_name");
        const email = document.getElementById("contact_email");
        const subject = document.getElementById("contact_subject");
        const message = document.getElementById("contact_message");

        e.preventDefault();

            const nameValue = name.value.trim();
            const emailValue = email.value.trim();
            const subjectValue = subject.value.trim();
            const messageValue = message.value.trim();

            if (nameValue === "") {
                this.setError(name, "Ad boş olamaz");
            } else {
                this.setSuccess(name);
            }

            if (emailValue === "") {
                this.setError(email, "E-posta boş olamaz");
            } else if (!this.isEmail(emailValue)) {
               this.setError(email, "Geçerli bir e-posta değil");
            } else {
                this.setSuccess(email);
            }

            if (subjectValue === "") {
                this.setError(subject, "Konu boş olamaz");
            } else {
                this.setSuccess(subject);
            }

            if (messageValue === "") {
                this.setError(message, "Mesaj boş olamaz");
            } else {
                this.setSuccess(message);
            }

            try {
              
                
             

            var data = JSON.stringify({
              "service_id": "yandexkto",
              "template_id": "template_wz6ul0j",
              "user_id": "user_GpQA79KqTn7MN0UfLRMJo",
              "template_params": {
                "from_name": nameValue,
                "message": messageValue
              },
              "accessToken": "246e193c2ee5b34351c178f5ef2db977"
            });
            
            var config = {
              method: 'post',
              url: 'https://api.emailjs.com/api/v1.0/email/send?service_id=yandexkto&template_id=template_wz6ul0j&user_id=user_GpQA79KqTn7MN0UfLRMJo&template_params&accessToken=246e193c2ee5b34351c178f5ef2db977',
              headers: { 
                'Content-Type': 'application/json'
              },
              data : data
            };
            
            axios(config)
            .then( (response) => {
                alert("Mesajınız başarıyla tarafımız iletildi!")

            })
            .catch(function (error) {
              console.log(error);
            });
            
        } catch (error) {
            console.log(error);

          }

        }

         setError(input, message) {
            const formControl = input.parentElement;
            const errorMsg = formControl.querySelector(".contact_input-msg");
            formControl.className = "form-control text-left error";
            errorMsg.innerText = message;
        }

 
         setSuccess(input) {
            const formControl = input.parentElement;
            formControl.className = "form-control success";
        }

         isEmail(email) {
            return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
        }


    render() {
    return (
        <Styles>
            {/* Main Wrapper */}
            <div className="main-wrapper contact-page">

                {/* Header 2 */}
                <HeaderTwo />

                {/* Breadcroumb */}
                <BreadcrumbBox title="İletişim" />

                {/* Contact Area */}
                <section className="contact-area">
                    <Container>
                        <Row>
                            <Col md="4">
                                <div className="contact-box-title">
                                    <h4>İletişim Bilgileri</h4>
                                </div>
                                <div className="contact-icon-box d-flex">
                                    <div className="icon">
                                        <i className="las la-map-marker"></i>
                                    </div>
                                    <div className="box-content">
                                        <h5>Konumumuz</h5>
                                        <p>{this.state.Adress_Text}</p>
                                    </div>
                                </div>
                                <div className="contact-icon-box d-flex">
                                    <div className="icon">
                                        <i className="las la-envelope-open"></i>
                                    </div>
                                    <div className="box-content">
                                        <h5>Mail Adresimiz</h5>
                                        <p>{this.state.Email_Adress}</p>
                                    </div>
                                </div>
                                <div className="contact-icon-box d-flex">
                                    <div className="icon">
                                        <i className="las la-phone"></i>
                                    </div>
                                    <div className="box-content">
                                        <h5>Telefon Numaramız</h5>
                                        <p>{this.state.Phone_Numer}</p>
                                    </div>
                                </div>
                          
                            </Col>
                            <Col md="8">
                                <div className="contact-form">
                                    <div className="form-title">
                                        <h4>Bize Ulaşın</h4>
                                    </div>
                                    <div className="form-box">
                                        <form id="form_contact" className="form">
                                            <Row>
                                                <Col md="6">
                                                    <p className="form-control">
                                                        <input type="text" placeholder="Adınız" id="contact_name" />
                                                        <span className="contact_input-msg"></span>
                                                    </p>
                                                </Col>
                                                <Col md="6">
                                                    <p className="form-control">
                                                        <input type="email" placeholder="Mail Adresiniz" id="contact_email" />
                                                        <span className="contact_input-msg"></span>
                                                    </p>
                                                </Col>
                                                <Col md="12">
                                                    <p className="form-control">
                                                        <input type="text" placeholder="Konu" id="contact_subject" />
                                                        <span className="contact_input-msg"></span>
                                                    </p>
                                                </Col>
                                                <Col md="12">
                                                    <p className="form-control">
                                                        <textarea name="message" id="contact_message" placeholder="Mesajınızı Giriniz"></textarea>
                                                        <span className="contact_input-msg"></span>
                                                    </p>
                                                </Col>
                                                <Col md="12">
                                                    <button onClick={this.onFormSubmit}>Mesaj Gönder</button>
                                                </Col>
                                            </Row>
                                        </form>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                    {/* Google Map */}
                    <GoogleMap />
                </section>

                {/* Footer 2 */}
                <FooterTwo />

            </div>
        </Styles>
    )
}}

export default Contact
