import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/faqEvent.js";
import axios from 'axios';

const accordionButton = document.querySelectorAll(".accordion-button");
        accordionButton.forEach(button => {
            button.addEventListener("click", () => {
                button.classList.toggle("active");
                const content = button.nextElementSibling;

                if (button.classList.contains("active")) {
                    content.className = "accordion-content show";
                    content.style.maxHeight = content.scrollHeight + "px";
                } else {
                    content.className = "accordion-content";
                    content.style.maxHeight = "0";
                }
            });
        });

class FaqEvent extends Component {
     constructor() {

            super();
        this.state = {
            arrayEvent: [],
        
        };
    }
    


     async componentDidMount() {

        await this._starting();

}


_starting = async () => {

     try{
     
     await axios.get('https://kto.stechomeyazilim.info:5000/event/select')
      .then(res => {
        
                    this.setState({
arrayEvent: res.data.cikti
                  }, () => {
                       
                  })
      })

    }
    catch (error) {
      
      console.log("error" + error)
    }


}

render() {
    return (
        <Styles>
            {/* Faq & Event */}
            <section className="event-faq-area">
                <Container>
                    <Row>
                        <Col md="12">
                            <div className="event-area">
                                <Row>
                                    <Col md="12">
                                        <div className="sec-title">
                                            <h4>Yaklaşan <span>Etkinlikler</span></h4>
                                        </div>
                                    </Col>
                                    <Col md="12">
                                        {
                                            this.state.arrayEvent.map((eventData, i) => (
                                                <div className="event-box d-flex" key={i}>
                                                    <div className="event-date text-center">
                                                        <p>{eventData.Event_Date_Start.substring(0,10)}</p>
                                                    </div>
                                                    <div className="event-details">
                                                        <h6><Link to={eventData.eventLink}>{eventData.Event_Title}</Link></h6>
                                                        <ul className="list-unstyled list-inline">
                                                            <li className="list-inline-item"><i className="las la-clock"></i>{eventData.Event_Date_Start.substring(0,10)}</li>
                                                            <li className="list-inline-item"><i className="las la-map-marker"></i>{eventData.Event_Location}</li>
                                                        </ul>
                                                        <p>{eventData.Event_Short_Text}</p>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                       
                    </Row>
                </Container>
            </section>
        </Styles>
    )}
}

export default FaqEvent
