  import React, { Component } from 'react';
import { Styles } from '../styles/courseCategory.js';
import axios from 'axios';

const url = 'https://adminkto.stechomeyazilim.info';

class CourseCategory extends Component {
     constructor() {

            super();
        this.state = {
        arrayLesson:[],
        };
    }
    


         async componentDidMount() {

        await this._starting();

}

deneme = async (value) => {
    console.log("sads", value)
this._starting(value);

}
_starting = async () => {
     try{
     
     await axios.get(`https://kto.stechomeyazilim.info:5000/lesson/select/${this.props._category_ID}`)
      .then(res => {
        
                    this.setState({
arrayLesson: res.data
                  }, () => {
console.log("dfsnfdkjgbk123213", res.data)
                  })
      })

    }
    catch (error) {
      
      console.log("error" + error)
    }


}
    render() {
        return (
            <Styles>
                {/* Course Tag */}
                <div className="course-category">
                    <h5>Proje Bölümleri</h5>
                    <ul className="category-item list-unstyled">

                      {
                                            this.state.arrayLesson.map((eventData, i) => (
                                                <li className="check-btn">
                            <label htmlFor="cat1"><input onClick= {() => this.deneme(eventData.Study_Class)}type="checkbox" id="cat1" className="check-box" />{eventData.Departments_Name}<span>({eventData.SUMStudy})</span></label>
                        </li>
                                            ))
                                        }

                       
                    
                    </ul>
                </div>
            </Styles>
        )
    }
}

export default CourseCategory
