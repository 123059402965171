import React from 'react';
import { Routes, Route } from "react-router-dom"
import HomeOne from './HomeOne'
import HomeTwo from './HomeTwo'
import About from './pages/about/About'
import CourseGrid from './pages/courses/CourseGrid'
import CourseList from './pages/courses/CourseList'
import CourseDetails from './pages/courses/CourseDetails'
import Instructor from './pages/instructor/Instructors'
import InstructorDetails from './pages/instructor/InstructorDetails'
import Gallery from './pages/gallery/Gallery'
import Events from './pages/events/Events'
import EventDetails from './pages/events/EventsDetails'
import ProjectUpload from './pages/projectupload/ProjectUpload'
import Projects from './pages/projects/Projects'
import DevamProje from './pages/projects/devamedenprojelerimiz'
import TamamProje from './pages/projects/tamamlananprojelerimiz'
import PageNotFound from './pages/404/PageNotFound'
import ComingSoon from './pages/comingsoon/ComingSoon'
import Inavasyon from './pages/inovasyon/Inovasyon'
import Iletisim from './pages/iletisim/Contact'
import Menu from './pages/menu/Menu'
import OgrenciProjeleri from './pages/ogrenciprojeleri/Project'
import Project2021 from './pages/ogrenciprojeleri/Project'
import Project2022 from './pages/ogrenciprojeleri/Project'
import TanıtımVideo from './pages/tanıtım/TanıtımVideo'
import TanıtımMateryal from './pages/tanıtım/TanıtımMateryal'
import Mevzuat from './pages/tanıtım/Mevzuat'
import HaberDetay from './pages/inovasyon/BlogDetails'
import Projeornekleri from './pages/projeornekleri/Project'


function App() {
    return (           
        <Routes>
   
                <Route exact path={"/"} element={<HomeOne/>} />
                <Route path={"/home-two"} element={<HomeTwo/>} />
                <Route path={"/about"} element={<About/>} />
                <Route path={"/course-grid"} element={<CourseGrid/>} />
                <Route path={"/course-list"} element={<CourseList/>} />
                <Route path={"/course-details"} element={<CourseDetails/>} />
                <Route path={"/ekibimiz"} element={<Instructor/>} />
                <Route path={"/ekibimiz-detay"} element={<InstructorDetails/>} />
                <Route path={"/gallery"} element={<Gallery/>} />
                <Route path={"/etkinlikler"} element={<Events/>} />
                <Route path={"/etkinlik-detay"} element={<EventDetails/>} />
                <Route path={"/proje-yukle"} element={<ProjectUpload/>} />
                <Route path={"/projelerimiz"} element={<Projects/>} />
                <Route path={"/404"} element={<PageNotFound/>} />
                <Route path={"/coming-soon"} element={<ComingSoon/>} />
                <Route path={"/inavasyon"} element={<Inavasyon/>} />
                <Route path={"/haber-detay"} element={<HaberDetay/>} />
                <Route path={"/devam-eden-bilimsel-projelerimiz"} element={<DevamProje/>} />
                <Route path={"/tamamlanan-bilimsel-projelerimiz"} element={<TamamProje/>} />
                <Route path={"/ogrenci-projeleri"} element={<OgrenciProjeleri/>} />
                <Route path={"/iletisim"} element={<Iletisim/>} />
                <Route path={"/menu"} element={<Menu/>} />
                <Route path={"/ogrenci-projeleri/2020-2021-akademik-yılı"} element={<Project2021/>} />
                <Route path={"/ogrenci-projeleri/2021-2022-akademik-yılı"} element={<Project2022/>} />
                <Route path={"/tanitimvideo"} element={<TanıtımVideo/>} />
                <Route path={"/tanitimmateryal"} element={<TanıtımMateryal/>} />
                <Route path={"/mevzuat"} element={<Mevzuat/>} />
                <Route path={"/proje-ornekleri"} element={<Projeornekleri/>} />


            </Routes>

      
    )
}



export default App;