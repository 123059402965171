import React, { Component } from 'react';
import { Styles } from "./styles/teamSlider.js";
import axios from 'axios';
import './css/jquery.flipster.min.css';
import moment from 'moment';



class TeamSlider extends Component {

             constructor() {

            super();
        this.state = {
            arrayEvent: [],
            currentDate:"",
        };
    }
    


     async componentDidMount() {


  this.setState({
currentDate: moment().format('YYYY-MM-D')
                  }, async() => {
                               await this._starting();

                  })




}


_starting = async () => {

     try{
     
     await axios.get('https://kto.stechomeyazilim.info:5000/event/select/null/0/9')
      .then(res => {
        
                    this.setState({
arrayEvent: res.data
                  }, () => {
                       
                  })
      })

    }
    catch (error) {
      
      console.log("error" + error)
    }
}

    render() {
       
        return (
            <Styles>








                {/* Team Slider */}
                <section className="team-member-area">
                  
                </section>
            </Styles>
        )
    }
}

export default TeamSlider
