import React, { Component } from 'react';
import Datas from '../data/counter/number-counter.json';
import { Container, Row, Col } from 'react-bootstrap';
import CountUp from 'react-countup';
import { Styles } from "./styles/numberCounter.js";
import axios from 'axios';

class NumberCounter extends Component {
  constructor() {

            super();
        this.state = {
            arrayCounter: [],
        };
    }
    
         async componentDidMount() {

        await this._starting();


}

_starting = async () => {

     try{
     
     await axios.get('https://kto.stechomeyazilim.info:5000/getCounter/select/')
      .then(res => {
        
                    this.setState({
arrayCounter: res.data
                  }, () => {
                       
                  })
      })

    }
    catch (error) {
      
      console.log("error" + error)
    }


}


    render() {
        return (
            <Styles>
                {/* Counter Area */}
                <section className="counter-area" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/${Datas.backgroundImage})` }}>
                    <Container>
                        <Row>
                            <Col md="12">
                                <div className="sec-title text-center">
                                </div>
                            </Col>

                           { this.state.arrayCounter.map((data, i) => (
                                                        <Col key = {i} md="3" xs="6">
                                                           <div className="counter-box">
                                                               <div className="counter-icon">
                                                                   <i className="las la-university"></i>
                                                               </div>
                                                               <div className="counter-number">
                                                                   <h4><CountUp  onEnd={({ pauseResume, reset, start, update }) => start()} end={data.Counter_Number} duration={5} delay={1.5} />+</h4>
                                                                   <p>{data.Counter_Title}</p>
                                                               </div>
                                                           </div>
                                                       </Col>
                                                       ))}
                           
                           
                        </Row>
                    </Container>
                </section>
            </Styles>
        )
    }
}

export default NumberCounter
