import React, { Component } from 'react';
import HeaderTwo from './components/HeaderTwo';
import HeroSlider from './components/HeroSlider';
import TeamSlider from './components/TeamSlider';
import NewsletterForm from './components/NewsletterForm';
import Footer from './components/Footer';
import CourseSlider from './components/CourseSlider';
import TubitakSlider from './components/TubitakSlider';
import NumberCounter from './components/NumberCounter';

export default class HomeOne extends Component {

    render() {
        return (
            <div className="main-wrapper" >

                <HeaderTwo />

                <HeroSlider />

                <CourseSlider />

                <TubitakSlider />

                <NumberCounter />

                <TeamSlider />

                <NewsletterForm />

                <Footer />

            </div >
        )
    }
}
