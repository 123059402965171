import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/newsletterForm.js";
import axios from 'axios';



class NewsletterForm extends Component{
            constructor() {
        super();
        this.state = {          
arraySubsCategory:[],
selectedCategoryID:0,
}
        this.onFormSubmit = this.onFormSubmit.bind(this);

}


     async componentDidMount() {
                               await this._starting();
}

_starting = async () => {

     try{
     
     await axios.get('https://kto.stechomeyazilim.info:5000/subscribeCategory/select/')
      .then(res => {
        
                    this.setState({
arraySubsCategory: res.data
                  }, () => {
                       
                  })
      })

    }
    catch (error) {
      
      console.log("error" + error)
    }
}



       onFormSubmit = async(e) => {
        const name = document.getElementById("name2");
        const email = document.getElementById("email2");
            e.preventDefault();

   console.log("adsdasd", name)

   if (this.state.selectedCategoryID === 0) {
             alert("Lütfen Akademisyen veya Öğrenci mi olduğunuzu seçiniz");
   }
       
if (name.value.trim() === ""){
                this.setError(name, "İsim Boş Geçilemez");
   }

if (email.value.trim() === ""){
                this.setError(email, "Mail Adresi Boş Geçilemez");
   }else if (!this.isEmail(email.value.trim())) {
                this.setError(email, "Yanlış Mail Formatı");
            }

if (name.value.trim() !== ""){
    if (email.value.trim() !== ""){

   if (this.state.selectedCategoryID !== 0) {

            const nameValue = name.value.trim();
            const emailValue = email.value.trim();

            if (nameValue === "") {
                this.setError(name, "İsim Boş Geçilemez");
            } else {
                this.setSuccess(name);
            }

            if (emailValue === "") {
                this.setError(email, "Mail Adresi Boş Geçilemez");
            } else if (!this.isEmail(emailValue)) {
                this.setError(email, "Yanlış Mail Formatı");
            } else {
                this.setSuccess(email);
            }


   axios({
            method: "POST",
            url: "https://kto.stechomeyazilim.info:5000/subscribe/insert",
            data: ({
        Subscribe_Name: nameValue,
        Subscribe_Mail:  emailValue,
        Subscribe_Category_ID: this.state.selectedCategoryID
    }),
            headers: {
              "Content-Type": "application/json"
            }
          }).then(res => {

  this.setState({isLoading:false});

         alert("Başarıyla Abonelik İşleminiz Gerçekleştirilmiştir");

          });
   }
        
}  
      
}  

        
    }

  

         setError(input, message) {
            const formControl = input.parentElement;
            const errorMsg = formControl.querySelector(".input-msg2");
            formControl.className = "form-control error";
            errorMsg.innerText = message;
        }

         setSuccess(input) {
            const formControl = input.parentElement;
            formControl.className = "form-control success";
        }

         isEmail(email) {
            return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
        }

  handleChangeCATEGORY = (event) => {
    this.setState({ selectedCategoryID: event.target.value });
  }


   render() {
    return (
        <Styles>
            {/* Newsletter Form */}
            <section className="newsletter-form-area">
                <Container>
                    <Row>
                        <Col md="12">
                            <div className="newsletter-container">
                                <div className="newsletter-box">
                                    <div className="sec-title text-center">
                                        <h4>Son Gelişmelerden Haberdar Ol</h4>
                                    </div>
                                    <form id="form2" className="form">
                                        <Row>
                                            <Col md="2">
                                                <p className="form-control">
                                                    <input type="text" placeholder="Adınız" id="name2" />
                                                    <span className="input-msg2"></span>
                                                </p>
                                            </Col>
                                            <Col md="3">
                                                <p className="form-control">
                                                    <input type="email" placeholder="Mail Adresiniz" id="email2" />
                                                    <span className="input-msg2"></span>
                                                </p>
                                            </Col>

                                             <Col md="3">
                                                 <div className="box" style = {{marginTop:12}}>
  <select onChange={this.handleChangeCATEGORY} placeholder="Akademik Yılı Seçiniz">
     { this.state.arraySubsCategory.map((data,i ) => ( 
                                                  <option  key = {i} value={data.ID} >{data.Category_Name}</option>                         ))
                                                                                                     }

  </select>
</div>


</Col>

                                            <Col md="3">
                                                <button onClick={this.onFormSubmit} ><i className="las la-envelope"></i>Abone Ol</button>
                                            </Col>
                                           
                                        </Row>
                                    </form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Styles>
    );
}}  

export default NewsletterForm
