import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/style.js';

import axios from 'axios';


const url = 'https://kto.stechomeyazilim.info';


class TanıtımMateryal extends Component {
 constructor(props) {
    super(props);
    this.state = {

      ArrayMaterials:[],
    };



  }

    async componentDidMount() {
await this._getVideo();
}



_getVideo= async () => {
        try {      
    

await axios.get('https://kto.stechomeyazilim.info:5000/promotionMaterials/select/')
      .then(res => {

  this.setState({
                ArrayMaterials : res.data

          }, () => {
          })

      })
  
              

        } catch (error) {
          
          console.log("error" + error)
        }
      }

    render() {
        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper blog-classic-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Tanıtım Materyal" />

                    {/* Blog Classic */}
                    <section className="blog-classic-area">
                        <Container>
                         
             
               {
                   this.state.ArrayMaterials.map((data, i) => ( 

<details>
  <summary>{data.Promotion_Document_Title}</summary>
  <div class="faq__content">

   <iframe
        src={"https://docs.google.com/viewer?url=" + url+ "/" + data.Promotion_Document_URL + "&embedded=true"}
        title="file"
        width="100%"
        height="320"
      ></iframe>


  </div>
</details>
                         ))
                                                    }

      
                        </Container>
                    </section>

                    {/* Footer 2 */}
                    <FooterTwo />

                </div>
            </Styles>
        )
    }



}

export default TanıtımMateryal