import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Swiper from 'react-id-swiper';
import { Styles } from "./styles/courseSlider.js";
import axios from 'axios';
import { Styles1 } from "./styles/iconBox.js";

const url = 'https://adminkto.stechomeyazilim.info';

class CourseSlider extends Component {
     constructor() {

            super();
        this.state = {
            arrayNews: [],
        feed: [],
        show:true, 
        };
    }
    


     async componentDidMount() {

        await this._starting();

}



_starting = async () => {

     try{
     
     await axios.get('https://kto.stechomeyazilim.info:5000/news/select')
      .then(res => {
        
                    this.setState({ arrayNews: res.data })
      })

    }
    catch (error) {
      
    }


}

handleModal(){ 
    
    this.setState({
        show:!this.state.show }, async() => {
        localStorage.setItem('showVideo', this.state.show);
      })
                          
    

  }  

    render() {
        const settings = {
            slidesPerView: 3,
            loop: true,
            speed: 1000,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },
            spaceBetween: 30,
            watchSlidesVisibility: true,
            pagination: {
                el: '.slider-dot.text-center',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                576: {
                    slidesPerView: 1
                },
                768: {
                    slidesPerView: 2
                },
                992: {
                    slidesPerView: 3
                }
            }
        };


        return (
            <Styles>
                 
            
                <section className="course-slider-area">


                    <Container>
                        <Row>
                           
                            <Col md="12" className="course-slider">
                            <div className="course-slider-left">

                          { this.state.arrayNews.length > 0 && (

                                <Swiper {...settings}>
                                    {
                                        this.state.arrayNews.slice(0,35).map((data, i) => (

                                            <div className="course-item" key={i}>
                                                { data.News_Title != null ? 
                                                <Link state={{ ID: data.ID }} to={{pathname: `/haber-detay/${(data.News_Title).replace(/\?/g, "-").replace(/\s+/g, '-').toLowerCase()}`}}>
                                                    <div className="course-image" style={{ backgroundImage: `url(${url + data.News_Image})` }}/>
                                                </Link> : null }
    
                                                <div className="course-content">
                                                { data.News_Title != null ?
                                                    <h6 style = {{textAlign:'center'}} className="heading"><Link state={{ ID: data.ID }}  to={{pathname: `/haber-detay/${(data.News_Title).replace(/\?/g, "-").replace(/\s+/g, '-').toLowerCase()}`}}>{(data.News_Title).substring(0,120)}</Link></h6> : null}
                                                 { data.News_Short_Text != null ? <p style = {{textAlign:'center'}} className="desc">..</p> : <p style = {{textAlign:'center'}} className="desc"> ...</p> }
                                                   
<Styles1>

                <section className="icon-box-area">       
                                        <div className="full-icon-box">
                                            <div className="icon-box d-flex">
                                                <div className="box-icon box2">
                                               <i className="flaticon-clipboard"></i>
                                                </div>
                                                { data.News_Title != null ?
                                             <h6 style = {{textAlign:'center'}} className="heading"><Link state={{ ID: data.ID }} to={{pathname: `/haber-detay/${(data.News_Title).replace(/\?/g, "-").replace(/\s+/g, '-').toLowerCase()}`}}><p className="desc">Detaya Git</p></Link></h6>

                                               : null }
                                            </div>
                                        </div>          
                </section>
         
</Styles1>

                                                    

                                                </div>
                    
                                            </div>
                                        ))
                                    }
                                </Swiper> )}

                                </div>

                            </Col>


                        </Row>
                    </Container>
                </section>
            </Styles>
        )
    }
}

export default CourseSlider
