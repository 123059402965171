import React, { Component } from 'react';
import Datas from '../../data/instructor/details.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Swiper from 'react-id-swiper';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/instructor.js';
import axios from 'axios';

class InstructorDetails extends Component {
         constructor() {

            super();
        this.state = {
            arrayEmployeesCv: [],
        
        };
    }
    


     async componentDidMount() {

        await this._starting();
        await this._employeesGet();

}


_employeesGet = async () => {

 try{
     
     await axios.get(`https://kto.stechomeyazilim.info:5000/employees/select/${this.props.location.state.ID}`)
      .then(res => {
        
                                          
                                           this.setState({
arrayEmployeesCv:res.data
                  }, () => {
                       
                  })
                   
      })

    }
    catch (error) {
      
      console.log("error" + error)
    }



}

_starting = async () => {

 try{
     
     await axios.get(`https://kto.stechomeyazilim.info:5000/employeesDetail/select/${this.props.location.state.ID}`)
      .then(res => {
        
         res.data.map((data, i) => (
                                          
                                           this.setState({
Empleyees_NameSurname: data.Empleyees_NameSurname,
Empleyees_Image: data.Empleyees_Image,
Employees_Mail_Adress: data.Employees_Mail_Adress,
Employees_Tasks:data.Employees_Tasks,
Employees_Phone_Number:data.Employees_Phone_Number,

Employess_FB_Adress:data.Employess_FB_Adress,
Employess_TW_Adress:data.Employess_TW_Adress,
Employess_LN_Adress:data.Employess_LN_Adress,
                  }, () => {
                      

                                       
 
                  })

                                        ))

                   
      })

    }
    catch (error) {
      
      console.log("error" + error)
    }



}

    render() {
        const settings = {
            slidesPerView: 3,
            loop: true,
            speed: 1000,
            autoplay: false,
            spaceBetween: 30,
            watchSlidesVisibility: true,
            pagination: {
                el: '.slider-dot.text-center',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                576: {
                    slidesPerView: 1
                },
                768: {
                    slidesPerView: 2
                },
                992: {
                    slidesPerView: 3
                }
            }
        };

        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper instructor-details-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Ekibimiz Detay" />

                    {/* Instructor Details Area */}
                    <section className="instructor-details-area">
                        <Container>
                            <Row>
                                <Col md="4">
                                    <div className="instructor-img">
                                        <img src={process.env.PUBLIC_URL + `/assets/images/team-7.jpg`} alt="" className="img-fluid" />
                                        <ul className="list-unstyled getintouch">
                                            <li><i className="las la-phone"></i>{this.state.Employees_Phone_Number}</li>
                                            <li><i className="lar la-envelope"></i>{this.state.Employees_Mail_Adress}</li>
                                        </ul>
                                        <ul className="social list-unstyled list-inline">
                                            <li className="list-inline-item"><a href={"https://twitter.com/" + "/"}><i className="fab fa-twitter"></i></a></li>
                                            <li className="list-inline-item"><a href={"https://linkedin.com/" + "/"}><i className="fab fa-linkedin-in"></i></a></li>
                                                                          </ul>
                                    </div>
                                </Col>
                                <Col md="8">
                                    <div className="instructor-content">
                                        <h4>{this.state.Empleyees_NameSurname}</h4>
                                        <span>{this.state.Employees_Tasks}</span>
                                    </div>
                                    <div className="qual-expe d-flex">
                                        <div className="qualification">
                                            <h5>CV</h5>

                                            

                              {     this.state.arrayEmployeesCv.map((data, i) => (
                                                                        <div className="qual-expe-box">
                                                                              <h6>{data.Employees_CV_Text}</h6>
                                                                          </div>
                              
                                                                      ))}

                                        </div>
                                        <div className="experiance">
                                            <h5>YIL</h5>

                              {     this.state.arrayEmployeesCv.map((data, i) => (
                                            <div className="qual-expe-box">
                                                                              <h6>{data.Employees_CV_DATE}</h6>
                                                                          </div>
                              
                                                                      ))}

                                        </div>
                                    </div>
                                </Col>
                             
                            </Row>
                        </Container>
                    </section>

                    {/* Footer 2 */}
                    <FooterTwo />

                </div>
            </Styles>
        )
    }
}

export default InstructorDetails