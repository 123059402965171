  import React, { Component } from 'react';
import { Styles } from '../styles/courseCategory.js';
import axios from 'axios';


class CourseCategory extends Component {
     constructor() {

            super();
        this.state = {
            arrayEventCategory: [],
        
        };
    }
    

         async componentDidMount() {

        await this._starting();

}


_starting = async () => {

     try{
     
     await axios.get('https://kto.stechomeyazilim.info:5000/eventCategory/select')
      .then(res => {
        
                    this.setState({
arrayEventCategory: res.data.cikti
                  }, () => {
                                              console.log("rs123123", res.data)

                  })
      })

    }
    catch (error) {
      
      console.log("error" + error)
    }


}
    render() {
        return (
            <Styles>
                {/* Course Tag */}
                <div className="course-category">
                    <h5>Etkinlik Sahipleri</h5>
                    <ul className="category-item list-unstyled">

                      {
                                            this.state.arrayEventCategory.map((eventData, i) => (
                                                <li className="check-btn">
                            <label htmlFor="cat1"><input type="checkbox" id="cat1" className="check-box" />{eventData.Category_Name}<span>(25)</span></label>
                        </li>
                                            ))
                                        }

                       
                    
                    </ul>
                </div>
            </Styles>
        )
    }
}

export default CourseCategory
