import React, { Component } from 'react';
import { Styles } from "./styles/heroSlider.js";
import axios from 'axios';

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "swiper/css/effect-cube"


import SwiperCore from 'swiper';


import { Navigation, Pagination,Autoplay,EffectCube } from 'swiper/modules';

const url="https://adminkto.stechomeyazilim.info"

SwiperCore.use([EffectCube,Autoplay,Pagination,Navigation]);


class HeroSlider extends Component {
  constructor() {
        super();
        this.state = {
            arraySlider: [],
        
        };
    }
    


     async componentDidMount() {
        await this._starting()
    }

_starting = async () => {

     try{
     
     await axios.get('https://kto.stechomeyazilim.info:5000/slider/select')
      .then(res => {
        
                    this.setState({
arraySlider: res.data
                  }, () => {
                       
                  })
      })

    }
    catch (error) {
      
      console.log("error" + error)
    }


}


    render() {
       

        return (
            <Styles>
                {/* Hero Slider */}
                <section className="hero-slider-area">
<div className="rectangle">
    <div className="trapezoid">
    <img alt="ktottologo" style = {{  position: 'absolute', 
   left:15, 
   top:5 
}} width="60" height="40" src={process.env.PUBLIC_URL + `/assets/images/logo_.png`} />
    <img alt="ktottologo" style = {{  position: 'absolute', 
   right:15, 
   top:5 
}} width="50" height="40" src={process.env.PUBLIC_URL + `/assets/images/logoorta.png`}  />
    </div>
                                        


    

</div>

 <Swiper  effect={'cube'} grabCursor={true} cubeEffect={{
  "shadow": false,
  "slideShadows": false,
  "shadowOffset": 0,
  "shadowScale": 0
}} spaceBetween={30} centeredSlides={true} autoplay={{
  "delay": 3500,
  "disableOnInteraction": false
}} pagination={{
  "clickable": true
}} navigation={true} className="mySwiper">

{ this.state.arraySlider.map((data, i) => (
        <SwiperSlide key = {i}>

                                <div className="slider-item">
                                    <div className="image-container">
                                        <img style={{ height: 450, width: '100%', objectFit: 'contain'}} src={url +  `${data.SliderImage}`} className="slider-image" alt={data.SliderImage} />
                                    </div>
                                    
                                </div>
                                     </SwiperSlide>

                            ))}

  </Swiper>

 </section>
            </Styles>
        )
    }
}

export default HeroSlider
