import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/style.js';
import CourseSidebar from './components/CourseSidebar';
import { Accordion, Card, Button } from 'react-bootstrap';

import axios from 'axios';

import { Styles1 } from './styles/faqs.js';

const urlLink = 'https://adminkto.stechomeyazilim.info';


class Mevzuat extends Component {
 constructor(props) {
    super(props);
    this.state = {

      ArrayMaterials:[],
    };



  }

     async componentDidMount() {


await this._starting('bos');
}

_starting = async (value) => {
       this.setState({ ArrayMaterials: [],
       docs : [] })
                 console.log("klfsmfdkl")

     try {      
await axios.get(`https://kto.stechomeyazilim.info:5000/getLegislation/select/`)
      .then(res => {

  this.setState({
                ArrayMaterials : res.data
          }, () => {

                 console.log("klfsmfdkl", res.data)
     res.data.map((eventData, i) => (
       this.setState({ docs: [...this.state.docs,{ uri : urlLink + "/" + eventData.Legislation_Document_URL}] })
                                            ))     

          })

      })
  
              

        } catch (error) {
          
          console.log("error" + error)
        }

}

_searchProject= async (value,value1) => {
       this.setState({ ArrayMaterials: [],
       docs : [] })

     try {      

await axios.get(`https://kto.stechomeyazilim.info:5000/project/search/${value}/${value1}`)
      .then(res => {

  this.setState({
                ArrayMaterials : res.data
          }, () => {



          })

      })
  
              

        } catch (error) {
          
          console.log("error" + error)
        }

}



    render() {
        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper blog-classic-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Mevzuat" />

                    {/* Blog Classic */}
                    <section className="blog-classic-area">
                        <Container>
                            <Row>
                                <Col lg="9" md="12">

                                                  <Styles1>

                                    <div className="section">

                <div className="container">
                    <div className="row">

                        {this.state.ArrayMaterials.map((item, i) => (
                                                <div className="col-lg-6">



                                                    <Accordion defaultActiveKey="0" className="with-gap" flush>

                                        <Card >
                                        <Accordion.Collapse  eventKey= {i === 0 || i === 1 ? "0" : "1"} className="collapseparent">
                                            <Card.Body>


 <iframe
        src={"https://docs.google.com/viewer?url=" + urlLink  + item.Legislation_Document_URL + "&embedded=true"}
        title="file"
        width="100%"
        height="320"
      ></iframe>


                                                </Card.Body>
                                        </Accordion.Collapse>
                                        <Card.Header>
                                            <Accordion.Toggle as={Button} variant="link" eventKey= {i === 0 || i === 1 ? "0" : "1"}>
                                            {item.Legislation_Title}

                                            
                                                                                            </Accordion.Toggle>
                                        </Card.Header>
                                    </Card> 
                                    </Accordion>
                                    </div>

                                    ))}


         
              
                    </div>
                </div >
            </div >
                                  
</Styles1>
                                  
                                      
                                </Col>


                            

  <Col lg="3" md="0">
                                    <CourseSidebar/>
                                </Col>

                                    </Row>
                        </Container>
                    </section>

                    {/* Footer 2 */}
                    <FooterTwo />

                </div>
            </Styles>
        )
    }



}

export default Mevzuat