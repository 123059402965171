import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Styles } from "./styles/pagination.js";

class Pagination extends Component {
    render() {
        return (
            <Styles>
                {/* Pagination */}
               { this.props.eventLenght < 5 ?  <ul className="pagination-box list-unstyled list-inline"> 
                <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-double-left"></i></Link></li>
                    <li className="list-inline-item"><Link onClick={() => this.props._starting(null,0,5)}>1</Link></li>
                    <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-double-right"></i></Link></li>
                     </ul> : (this.props.eventLenght > 5 && this.props.eventLenght < 10) ? 
                     <ul className="pagination-box list-unstyled list-inline"> 
                <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-double-left"></i></Link></li>
                    <li className="list-inline-item"><Link onClick={() => this.props._starting(null,0,5)}>1</Link></li>
                    <li className="list-inline-item"><Link onClick={() => this.props._starting(null,5,5)}>2</Link></li>
                    <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-double-right"></i></Link></li>
                     </ul> : (this.props.eventLenght > 10 && this.props.eventLenght < 15) ?
                      <ul className="pagination-box list-unstyled list-inline"> 
                <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-double-left"></i></Link></li>
                    <li className="list-inline-item"><Link onClick={() => this.props._starting(null,0,5)}>1</Link></li>
                    <li className="list-inline-item"><Link onClick={() => this.props._starting(null,5,5)}>2</Link></li>
                    <li className="list-inline-item"><Link onClick={() => this.props._starting(null,10,5)}>3</Link></li>
                    <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-double-right"></i></Link></li>
                     </ul> : (this.props.eventLenght > 15 && this.props.eventLenght < 20) ?
                      <ul className="pagination-box list-unstyled list-inline"> 
                <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-double-left"></i></Link></li>
                    <li className="list-inline-item"><Link onClick={() => this.props._starting(null,0,5)}>1</Link></li>
                    <li className="list-inline-item"><Link onClick={() => this.props._starting(null,5,5)}>2</Link></li>
                    <li className="list-inline-item"><Link onClick={() => this.props._starting(null,10,5)}>3</Link></li>
                    <li className="list-inline-item"><Link onClick={() => this.props._starting(null,15,5)}>4</Link></li>
                    <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-double-right"></i></Link></li>
                     </ul> : (this.props.eventLenght > 20 && this.props.eventLenght < 25) ?
                      <ul className="pagination-box list-unstyled list-inline"> 
                <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-double-left"></i></Link></li>
                    <li className="list-inline-item"><Link onClick={() => this.props._starting(null,0,5)}>1</Link></li>
                    <li className="list-inline-item"><Link onClick={() => this.props._starting(null,5,5)}>2</Link></li>
                    <li className="list-inline-item"><Link onClick={() => this.props._starting(null,10,5)}>3</Link></li>
                    <li className="list-inline-item"><Link onClick={() => this.props._starting(null,15,5)}>4</Link></li>
                    <li className="list-inline-item"><Link onClick={() => this.props._starting(null,20,5)}>4</Link></li>
                    <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-double-right"></i></Link></li>
                     </ul> :
                   <ul className="pagination-box list-unstyled list-inline"> 
                <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-double-left"></i></Link></li>
                    <li className="list-inline-item"><Link onClick={() => this.props._starting(null,0,5)}>1</Link></li>
                    <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-double-right"></i></Link></li>
                     </ul>
           }

            </Styles>
        )
    }
}

export default Pagination
