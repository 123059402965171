export const colors = {

    bg1: "#182B49",
    bg2: "#F6F9FF",
    gr_bg: "linear-gradient(90deg, #50B7CF   0%, #182B49 100%)",
    gr_bg2: "linear-gradient(90deg, #182B49 0%, #50B7CF 100%)",
    copy_bg: "#122340",
    blue: "#2c97ea",
    green: "#00d2ee",
    green2: "#50B7CF",
    red: "#ff6f6f",
    purple: "#84479c",
    yellow: "#fbab19",
    black1: "#182B49",
    black2: "#444444",
    text1: "#555555",
    text2: "#666666",
    text3: "#969696",
    text4: "#aaaaaa",
    text5: "#cccccc",
    border1: "#eeeeee",
    border2: "#3e3e3e",
    border3: "#dddddd",
    footer1: "#1a1b25",
    footer2: "#16171f",
    ftext: "#8D8E92",

    ktoColor: "#00d2ee",

    

}

export const fonts = {

    roboto: "'Roboto', sans-serif",
    poppins: "'Poppins', sans-serif",

}