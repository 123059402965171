  import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/style.js';
import ReactPlayer from 'react-player'

import axios from 'axios';


const url =
  "https://adminkto.stechomeyazilim.info";



class TanıtımVideo extends Component {
 constructor(props) {
    super(props);
    this.state = {

      ArrayVideo:[],
    };



  }

    async componentDidMount() {
await this._getVideo();
}



_getVideo= async () => {
        try {      
    

await axios.get('https://kto.stechomeyazilim.info:5000/promotionVideo/select/')
      .then(res => {

  this.setState({
                ArrayVideo : res.data

          }, () => {
          })

      })
  
              

        } catch (error) {
          
          console.log("error" + error)
        }
      }

    render() {
        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper blog-classic-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Tanıtım Video" />

                    {/* Blog Classic */}
                    <section className="blog-classic-area">
                        <Container>
                         
             
               {
                   this.state.ArrayVideo.map((data, i) => ( 

<details>
  <summary>{data.Promotion_Video_Title}</summary>
  <div className="faq__content">

     <ReactPlayer
                url= {url + data.Promotion_Video_URL}
              controls={true}
              width={'100%'}
            />

  </div>
</details>
                         ))
                                                    }

      
                        </Container>
                    </section>

                    {/* Footer 2 */}
                    <FooterTwo />

                </div>
            </Styles>
        )
    }



}

export default TanıtımVideo